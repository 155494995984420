// @flow
import * as React from 'react'
import styled from 'styled-components'
import { ms } from 'modules/browser/const'
import { fetchHits } from './utils'
import ProductWidget from 'theme/molecules/ProductWidget'
import Pagination from 'theme/molecules/Pagination'
import * as t from './types'
import * as actions from './actions'
import useInView from 'hooks/useInView'
import historyState from 'utils/historyState'

export default function CurratedSearchWidget(props: t.Props) {
  const [hits, page, setPage] = useHits(
    props.hitsPerPage,
    props.queryStrings,
    props.context.initialHits
  )
  const [ref, inView] = useInView()
  const wasInView = React.useRef(false)
  const listName = 'cms-currated-search-widget'

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => actions.click(props)

  if (!hits.length) return null

  return (
    <Wrapper
      data-cy-ctx="organisms/CurratedSearchWidget"
      ref={ref}
      className="CurratedSearchWidget"
      isThreeProductWidget={props.hitsPerPage === 3}
      onClick={handleClick}
    >
      <div>
        <div className="products">
          {hits.map((hit, i) => (
            <ProductWidget
              key={hit.objectID}
              product={hit}
              listName={listName}
              index={i + page * props.hitsPerPage}
            />
          ))}
        </div>

        {props.showPagination && props.context.totalPages > 1 && (
          <div className="pagination" data-cy-state="show-pagination">
            <Pagination onChange={setPage} page={page} totalPages={props.context.totalPages} />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

function useHits(hpp, queryStrings, initialHits) {
  const [hits, setHits] = React.useState(() => {
    const state = historyState.load(queryStrings.join(''))
    if(!state) return initialHits
    return state.hits
  })
  const [page, setPage] = React.useState(() => {
    const state = historyState.load(queryStrings.join(''))
    if(!state) return 0
    return state.page
  })
  const isFirstRender = React.useRef(true)

  React.useEffect(() => {
    historyState.save(queryStrings.join(''), {hits, page})
  }, [hits,page, queryStrings])

  // do something
  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    fetchHits(hpp, queryStrings, page)
      .then(([hits]) => setHits(hits))
      .catch(() => setPage(0))
  }, [page, hpp, queryStrings])

  return [hits, page, setPage]
}

const Wrapper = styled.div`
  > div {
    background: #f8f8f8;
    padding: 30px 15px;

    > .products {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;

      @media (min-width: ${ms.LAPTOP}px) {
        grid-template-columns: repeat(3, 1fr);
      }

      ${(props) =>
        props.isThreeProductWidget &&
        `
        > .ProductWidget:nth-child(3) {display:none;}
        @media (min-width: ${ms.LAPTOP}px){
          > .ProductWidget:nth-child(3) {display:block;}
        }
      `}
    }

    > .pagination {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
`
